<template>
  <main>
    <div
      class="user__page"
      id="sayl-challenge-page-resto"
      v-if="!loading"
    ></div>

    <div
      class="user__loader"
      v-else>
      <ui-loader />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadScript } from '@/helpers/loadscript.js'
import MixinChallenge from './mixin.js'

export default {
  name: 'ViewChallenges',

  mixins: [ MixinChallenge ],

  computed: {
    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    hasChallenges() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false) &&
        this.$basil.get(this.$challenge, 'active', false)
    },
  },

  methods: {
    reset() {
      this.loading = true
      this.$challenge.getChallenges({})
        .then(() => this.$challenge.getSettings())
        .then(() => this.isLogged ? this.$challenge.getCompletions({}) : Promise.resolve())
        .finally(() => {
          this.loading = false
          setTimeout(() => loadScript(this.scriptUrl, this.scriptLoaded), 50)
        })
    },
  },
}
</script>

